<template>
    <div class="footer">
        <div class="bottom-top flex alit justc">
            <div class="flexCol alit">
                <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/%E5%BF%AB.png"
                    style="width: 33px;height: 33px;" alt="" />
                <div class="bottom-top-title">多仓直发，高效配送</div>
            </div>
            <div class="flexCol alit" style="margin: 0 90px;">
                <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/%E4%BC%98.png"
                    style="width: 33px;height: 33px;" alt="" />
                <div class="bottom-top-title">精选品质，精准集采</div>
            </div>
            <!-- <div class="flexCol alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/%E6%8D%B7.png"
					style="width: 33px;height: 33px;" alt="" />
				<div class="bottom-top-title">操作便捷，支付多样</div>
			</div> -->
            <div class="flexCol alit">
                <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/%E7%9C%81.png"
                    style="width: 33px;height: 33px;" alt="" />
                <div class="bottom-top-title">产地直供，减少环节</div>
            </div>
            <div class="flexCol alit" style="margin: 0 0 0 90px;">
                <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/%E5%AE%89.png"
                    style="width: 33px;height: 33px;" alt="" />
                <div class="bottom-top-title">智慧农业，品控溯源</div>
            </div>
        </div>
        <div class="banxin">
            <div class="top flex justc">

                <!-- <div class="centerBox">
					<p class="title">关注重庆火锅产业要素交易平台</p>
					<p>专注于客单价，转化率提升！</p>
					<p>便捷高效，助力各大商家提升客单价，</p>
					<p>客户引导，提高复购，</p>
					<p>只为更好的服务每一位用户。</p>
				</div> -->
                <div class="rightBox">
                    <div class="aboutUs">
                        <p class="title pointer" @click="goPage('aboutUs')">关于我们</p>
                        <p class="pointer" @click="goPage('contactUs')">联系我们</p>
                        <p class="pointer" @click="applyMerchant">管理中心</p>
                    </div>
                    <div class="aboutUs">
                        <p class="pointer" @click="goPage('procurementGuidelines')">采购指南</p>
                        <p class="pointer" @click="goPage('privacyPolicy')">隐私政策</p>
                    </div>
                    <div class="aboutUs">
                        <p class="title" @click="goPage('smartAgriculture')">智慧农业</p>
                        <p class="pointer" @click="goPage('oneItemOneCode')">一物一码</p>
                    </div>
                    <div class="aboutUs">
                        <p class="title" @click="goPage('financialService')">金融服务</p>
                        <p class="pointer" @click="goPage('friendshipLink')">友情链接</p>
                    </div>

                    <!-- 	<div class="leftBox flexCol" style="margin: 0 -80px 0 200px;">
						<p class="title" style="font-size: 17px;">客服电话</p>
						<p class="number" style="width: 300px;font-size: 16px;">电话：139 8305 7567</p>
						<p class="time" style="font-size: 16px;">服务时间：09:00 - 23:00</p>
						<div class="consult">在线咨询</div>
					</div> -->
                    <div class="qrCode" style="margin-right: 10px;margin-left: 120px;">
                        <div class="flexCol alit title">
                            <div class="" style="font-size: 14px">下载App</div>
                            <div class="" style="font-size: 12px;margin: 10px 0 5px 0;">重庆火锅产业要素交易平台</div>
                        </div>
                        <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/appcode.png" alt="" />
                    </div>
                    <div class="qrCode">
                        <p class="title" style="font-size: 14px;margin-bottom: 25px;">关注微信小程序</p>
                        <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/index/minQRCode.jpg"
                            alt="" />
                    </div>
                </div>
            </div>
            <p class="bottom" style="margin-bottom: 5px;">
                &copy;营业执照|增值电信业务经营许可证
                <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/%E7%BD%91%E8%AD%A6.png"
                    style="width:20px;margin-left: 20px;margin-right: 5px;" alt="" />
                <a href="https://www.cpd.com.cn/" rel="noreferrer" target="_blank">网络警察提醒您</a>
                <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83.png"
                    style="width:20px;margin-left: 20px;margin-right: 5px;" alt="" />
                <a href="https://ggfw.cnipa.gov.cn/" rel="noreferrer" target="_blank">国家知识产权公共服务网</a>
            </p>
            <p class="bottom">
                &copy;2017-2024重庆火锅产业要素交易平台版权所有，并保留所有权利 &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank">渝ICP备2023010903号-3</a>
                <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/index/ga.png"
                    style="width:20px;margin-left: 20px;margin-right: 5px;" alt="" />
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50019002504107/" rel="noreferrer"
                    target="_blank">渝公网安备50019002504107</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        openNew() {
            window.open('http://ccb.com/chn/smallcompany/index.shtml')
        },
        goPage(e) {
            this.$router.push(e)
        },
        applyMerchant() {
            window.open('https://merchant.huoguogo.cn/')
        }
    }
}
</script>

<style lang="scss" scoped>
.bottom-top-title {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #141414;
    margin-top: 9px;
}

.bottom-top {
    width: 100%;
    height: 84px;
    background: #F7F7F7;
}

.footer {
    width: 100%;
    height: 410px;
    // background-color: #ea5a1c;
    // background: #ee1d24;
    color: #777777;
    overflow: hidden;
    // background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012431%402x.png');
    // background-size: 100% 100%;

    .banxin {
        padding-top: 30px;
        width: 100%;
        height: 410px;
        margin: 0 auto;
        // background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012431%402x.png');
        // background-size: 100% 100%;
        background-color: #F7F7F7;
        border-top: 1px solid #C2CDD7;

        .top {
            // margin-top: 30px;
            display: flex;
            margin-bottom: 38px;
            // text-align: center;

            .leftBox {
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 16px;
                    margin-bottom: 33px;
                }

                .number {
                    font-size: 40px;
                    margin-bottom: 12px;
                    font-weight: 500;
                }

                .time {
                    font-size: 18px;
                    margin-bottom: 33px;
                    color: #777777;
                }

                .consult {
                    cursor: pointer;
                    // margin: 0 auto;
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    border: 1px solid #777777;
                    box-sizing: border-box;
                    opacity: 1;
                    text-align: center;
                }
            }

            .centerBox {
                flex: 1;
                border-left: 2px solid rgba($color: #777777, $alpha: 0.15);
                border-right: 2px solid rgba($color: #777777, $alpha: 0.15);

                p {
                    font-size: 16px;
                    line-height: 25px;
                }

                .title {
                    margin-bottom: 33px;
                }
            }

            .rightBox {
                display: flex;

                .aboutUs {
                    margin: 0 30px;

                    p {
                        margin-bottom: 20px;
                    }

                    .title {
                        margin-bottom: 20px;
                        cursor: pointer;
                    }
                }

                .qrCode {
                    // margin-top: -10px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;

                    .title {
                        // margin-bottom: 33px;
                        width: 165px;
                        text-align: center;
                    }

                    img {
                        width: 106px;
                        height: 106px;
                    }
                }
            }
        }

        .bottom {
            text-align: center;
            font-size: 12px;
            line-height: 0;
            color: #777777;
            // display: flex;
            // align-items: center;
            // justify-content: center;
        }
    }
}
</style>