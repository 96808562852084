<template>
	<div class="flexCol alit">
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/financialService/1%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/financialService/2%402x.png" width="100%"
			alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/financialService/3%402x.png" width="70%"
				alt="" style="margin: 30px 0;" />
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style>
</style>