<template>
	<div class="flexCol alit">
		<video
			src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/%E4%B8%AD%E5%9B%BD%E7%81%AB%E9%94%85%E4%B9%8B%E9%83%BD.mp4"
			style="height: 600px;" controls
			poster="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/appSwpier/0cbf456dfe12bfe7ef0a83bdef083aa.jpg"></video>
		<!-- poster="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/gs.png" -->
		<div class="about-us">关于我们</div>
		<div class="flex">
			<div class="">
				<!-- <div class="about-desc">
					重庆火锅产业要素交易平台在国家乡村振兴战略下，由重庆市政府主导，重庆市商务委牵头，渝中区政府与重庆火锅产业协会联动，国内知名火锅企业、供应链企业和金融主体参与共建的全要素平台，致力于推进农产品一二三产深度融合，构建引领全球、融合高效的现代火锅产业体系，实现让全球每个人都吃上安全健康的食材之初心。
				</div>
				<div class="about-desc">
					重庆火锅产业要素交易平台总部展厅位于重庆渝中区环球金融中心13层，链接重庆火锅产业协会1700家企业会员、本土1.6万家火锅企业和3.2万家火锅门店，为全国20多万家重庆火锅品牌门店和50多万家地方火锅品牌门店提供服务，打造全球火锅食材交易中心、全国火锅标准制定发布中心、火锅美食文化创新交流中心和火锅产业大数据中心。
				</div>
				<div class="about-desc">
					重庆火锅产业要素交易平台基于中国火锅产业发展有限公司与世界火锅产业联盟资源，为全球30多个国家的火锅门店助力发展，打造以重庆火锅为品牌的”世界火锅之都“，让重庆火锅走向世界。
				</div>
				<div class="about-desc">
					重庆火锅产业要素交易平台探索“种养殖基地+供应链金融+火锅门店”模式，严把食品原料准入关和进货查验，推广一物一码食材溯源，评定五星级绿色餐厅和5A级供应商，数字化赋能全产业链，推动火锅产业量质齐升，力争2027年实现重庆”33618“集群的火锅全产业链目标5000亿。
				</div> -->

				<div class="about-desc">
					  重庆火锅产业要素交易中心是重庆市政府主导，重庆市商务委牵头，渝中区政府与重庆市火锅产业协会倾力打造，平台汇聚国内知名火锅企业、供应链企业和金融主体参与共建，致力于“去中间环节、去资本投资”的全新发展模式，推进一二三产业深度融合发展，切实降低经营成本，保障产品质量及安全。
				</div>
				<div class="about-desc">
					  重庆火锅产业要素交易中心位于重庆市渝中区环球金融中心13楼，线上运行了“重庆火锅产业要素交易平台"APP、小程序、公众号、PC端、H5等五网同步平台，线下设置了占地约1000平米的食材展示集采大厅。厅内设有干副展区、原材料展区、粮油粉面展区、火锅底料展区、共享会议室、洽谈室、直播间、办公区、新产品发布区等。功能展区呈现出线上线下融合，高效赋能供给的一站式服务新场景。产业要素交易中心链接重庆市火锅产业协会1700家会员单位、3.6万家火锅门店及1000余家原材料采购需求企业作为平台的期权股东，在重庆市火锅产业协会的监督下，实行源头集采、基地直供、厂家直销，植入银行信贷产品，构建“供、需仓、配”于一体的闭环管理模式，彻底解决“收款难”“融资难”“采购回扣”等制约户亡业发展的难点和乱象，确保交易产品健康安全、等级分明、质优价廉，促进重庆火锅产业风清气正、气正心齐、心齐事成，助推重庆火锅产业高质量发展。
				</div>
			</div>
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/gs.png"
				style="width: 420px;height: 580px;margin-left: 25px;" alt="" />
		</div>
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/%E7%9F%A9%E5%BD%A2%203%402x.png"
			style="width: 1170px;height: 13px;margin: 45px 0 33px 0;" alt="" />
		<div class="about-us">企业文化</div>
		<div class="flex alit">
			<div class="" style="position: relative;width: 356px;height: 274px;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/gy1.png"
					style="width: 356px;height: 274px;" alt="" />
				<div class="qytt">
					<div class="qytt1">企业使命</div>
					<div class="qytt2">用科技推动农业产业升级 </div>
				</div>
			</div>
			<div class="about-desc2">平台将以火锅食材产业集群式、规模化、品牌化发展为引领以'火锅产业数智大脑’建设为核心，推进火锅食材一二三产深度融合，构建覆盖全国乃至全球的火锅产业交易中心，力争到
				2027
				年形成重庆火锅千亿级消费、千亿级底料生产、千亿级食材供应、千亿级预菜加工和千亿级火锅饮品及餐具器皿等为支撑的万亿级火锅产业总部经济。
			</div>
		</div>
		<div class="flex alit" style="margin: 20px 0 0 0;">
			<div class="" style="position: relative;width: 356px;height: 274px;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/gy2.png"
					style="width: 356px;height: 274px;" alt="" />
				<div class="qytt">
					<div class="qytt1">企业愿景</div>
					<div class="qytt2">让农民更富裕、让居民更健康 </div>
				</div>
			</div>
			<div class="about-desc2">
				把重庆打造成为世界火锅之都、火锅食材集散中心，全国火锅标准制定和发布中心，全国火锅美食文化创新和交流中心，实现重庆火锅产业要素“买全球”“卖全球”把重庆火锅打造成为世界级品牌。
			</div>
		</div>


		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/%E7%9F%A9%E5%BD%A2%203%402x.png"
			style="width: 1170px;height: 13px;margin: 45px 0 33px 0;" alt="" />
		<div class="about-us">企业展示</div>
		<div class="flex">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/1.png"
				style="width: 350px;height: 232px;" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/2.png"
				style="width: 350px;height: 232px;margin: 0 22px;" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/3.png"
				style="width: 350px;height: 232px;" alt="" />
		</div>
		<div class="flex" style="margin: 24px 0 60px 0;">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/4.png"
				style="width: 350px;height: 232px;" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/5.png"
				style="width: 350px;height: 232px;margin: 0 22px;" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/6.png"
				style="width: 350px;height: 232px;" alt="" />
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style>
	.qytt2 {
		font-family: Microsoft YaHei;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-top: 10px;
	}

	.qytt1 {
		font-family: Microsoft YaHei;
		font-weight: 400;
		font-size: 24px;
		color: #FFFFFF;
		margin-top: 75px;
	}

	.qytt {
		width: 354px;
		height: 137px;
		position: absolute;
		bottom: 0;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/aboutUs/gywmbg.png');
		background-size: 100% 100%;
		text-align: center;
	}

	.about-desc {
		width: 644px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		font-size: 18px;
		color: #000000;
		text-indent: 3ch;
		line-height: 36px;
	}

	.about-desc2 {
		width: 710px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		font-size: 18px;
		color: #000000;
		text-indent: 3ch;
		line-height: 36px;
		margin-left: 32px;
	}

	.about-us {
		font-family: Microsoft YaHei;
		font-weight: 400;
		font-size: 29px;
		color: #000000;
		width: 1100px;
		margin: 53px 0 27px 0;
		text-align: center;
	}
</style>