<template>
	<div class="">
		<div class="ys-title">重庆火锅产业要素交易平台隐私政策</div>
		<div class="flex justb">
			<div class=""></div>
			<div class="gxsj">本版更新日期：2024年3月24日</div>
		</div>
		<div class="flexCol alit">
		<!-- 	<div class="ys-content">
				【引言】<br />
				欢迎您使用我们的产品和服务！我们深知企业/个人信息（下称“信息”）对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的信息安全可控。基于此，重庆火锅产业要素交易平台服务提供者（或简称“我们”）制定本《重庆火锅产业要素交易平台隐私政策》（下称“本政策”/“本隐私政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的信息以及您可以如何管理您的信息，以便您更好地作出适当的选择。
				<br /><br />在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。除本政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向您说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本政策的一部分，并与本政策具有同等效力。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。
				<br /><br />您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要信息，相关附加功能的开启、处理非必要信息以及处理敏感信息，我们会根据您的实际使用情况单独征求您的同意。
				<br /><br />本隐私政策将帮助您了解以下内容：
				一、适用范围
				二、信息收集及使用
				三、数据使用过程中涉及的合作方及转移、公开信息
				四、您的权利
				五、信息的存储
				六、政策的更新
				七、未成年人保护
				八、联系我们
				附录：相关定义
				一、适用范围

				本政策适用于重庆火锅产业要素交易平台服务提供者以网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。我们及关联公司就其向您提供的产品或服务单独设有隐私政策的（如:《重庆火锅产业要素交易平台商家版隐私政策》《重庆火锅产业要素交易平台工业品隐私政策》），则相应产品或服务适用相应隐私政策。

				除本政策说明的相关信息收集使用活动外，本政策不适用于重庆火锅产业要素交易平台卖家及其他第三方向您提供的其他服务，其他服务适用其向您另行说明的信息收集使用规则。例如，当您加入卖家或品牌的会员时，则此类卖家或品牌对会员信息的使用适用其专门向您说明的规则。


			</div> -->
			<div class="ys-content" v-html="wordText"></div>
		</div>
	</div>
</template>

<script>
	import mammoth from "mammoth";
	export default {
		components: {},
		data() {
			return {
				wordText: "",
				wordURL: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/privacyPolicy/%E9%87%8D%E5%BA%86%E7%81%AB%E9%94%85%E4%BA%A7%E4%B8%9A%E8%A6%81%E7%B4%A0%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0%E7%94%A8%E6%88%B7--%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.docx' //文件地址
			}
		},
		mounted() {
			this.getWordText();
		},
		methods: {
			getWordText() {
				const xhr = new XMLHttpRequest();
				xhr.open("get", this.wordURL, true);
				xhr.responseType = "arraybuffer";
				xhr.onload = () => {
					if (xhr.status == 200) {
						mammoth.convertToHtml({
							arrayBuffer: new Uint8Array(xhr.response)
						}).then((resultObject) => {
							this.$nextTick(() => {
								this.wordText = resultObject.value;
							});
						});
					}
				};
				xhr.send();
			}
		}
	}
</script>

<style>
	p{
	}
	.ys-content {
		width: 1120px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		font-size: 13px;
		color: #707070;
		line-height: 32px;
		white-space: pre-wrap;
		margin-bottom: 30px;
	}

	.gxsj {
		font-family: Microsoft YaHei;
		font-weight: 400;
		font-size: 13px;
		color: #707070;
		margin-right: 410px;
	}

	.ys-title {
		font-family: Microsoft YaHei;
		font-weight: 900;
		font-size: 20px;
		color: #666666;
		text-align: center;
		margin: 50px 0;
	}
</style>