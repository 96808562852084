<template>
	<div class="flexCol alit">
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/procurementGuidelines/1%402x.png"
			width="100%" alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/procurementGuidelines/2%402x.png"
			width="80%" alt="" style="margin-top: 40px;" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/procurementGuidelines/3%402x.png"
			width="100%" alt="" style="margin: 30px 0;" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/procurementGuidelines/4%402x.png"
			width="80%" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/procurementGuidelines/3%402x.png"
				width="100%" alt="" style="margin: 30px 0;" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/procurementGuidelines/5%402x.png"
			width="80%" alt="" style="margin-bottom: 30px;" />
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style>
</style>