<template>
	<div class="flexCol alit">
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/smartAgriculture/1%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/smartAgriculture/2%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/smartAgriculture/3%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/smartAgriculture/4%402x.png" width="80%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/smartAgriculture/5%402x.png" width="60%"
			alt="" style="margin-bottom: 30px;" />
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style>
</style>