<template>
	<div class="flexCol alit ">
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/concatUs/top.png" style="width: 100%;"
			alt="" />
		<div class="flexCol alit justc">
			<div class="center-style flex alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/concatUs/gsdz.png"
					style="width: 154px;height: 149px;" alt="" />
				<div class="" style="margin-left: 100px;">
					<div class="center-title">公司地址</div>
					<div class="center-desc" style="margin-top: 23px;">地 址：重庆市渝中区民族路188号环球金融中心13楼</div>
				</div>
			</div>

			<div class="center-style flex alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/concatUs/lxdh.png"
					style="width: 154px;height: 149px;" alt="" />
				<div class="" style="margin-left: 100px;">
					<div class="center-title">联系电话</div>
					<div class="center-desc" style="margin-top: 23px;">电 话：023-63039959</div>
					<div class="center-desc" style="margin-top: 23px;">手 机：13983057567</div>
					<div class="center-desc" style="margin-top: 23px;">用户服务热线：023-68888084</div>
				</div>
			</div>

			<div class="center-style flex alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/concatUs/yx.png"
					style="width: 154px;height: 149px;" alt="" />
				<div class="" style="margin-left: 100px;">
					<div class="center-title">企业邮箱</div>
					<div class="center-desc" style="margin-top: 23px;">合作/咨询：1025421331@qq.com</div>
				</div>
			</div>
		</div>

		<div id="map" style="width: 1170px; height: 450px;margin: 50px 0;"></div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref
	} from 'vue';
	import AMapLoader from '@amap/amap-jsapi-loader';

	const map = ref(null);

	onMounted(() => {
		AMapLoader.load({
			key: 'b495ac24ca6062feb0025a227065c103', // 高德地图API Key
			version: '2.0', // 指定API版本
			plugins: ['AMap.Geolocation', 'AMap.PlaceSearch', 'AMap.Scale'], // 需要使用的插件
		}).then((AMap) => {
			map.value = new AMap.Map('map', {
				zoom: 18, // 初始缩放级别
				center: [106.577776, 29.558986], // 初始中心点坐标
			});

			// 点标记显示内容，HTML要素字符串
			var markerContent = '' +
				`<div class="flex-col alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/concatUs/addr.png" style="width:54px;height:78px;">
				</div>`;
			// <div style="width:150px">重庆环球金融中心</div>
			var marker = new AMap.Marker({
				position: [106.577776, 29.558986],
				// 将 html 传给 content
				content: markerContent,
				// 以 icon 的 [center bottom] 为原点
				offset: new AMap.Pixel(-30, -78)
			});

			map.value.add(marker);

			map.value.on('click', (event) => {
				// event.lnglat 获取点击的经纬度
				console.log(event.lnglat);
			});
		}).catch((e) => {
			console.error(e);
		});
	});
</script>

<style>
	.center-desc {
		font-family: Microsoft YaHei;
		font-weight: 400;
		font-size: 18px;
		color: #000000;
	}

	.center-title {
		font-family: Microsoft YaHei;
		font-weight: bold;
		font-size: 30px;
		color: #000000;
	}

	.center-style {
		width: 1054px;
		background: #FFFFFF;
		box-shadow: 1px 2px 18px 0px rgba(0, 0, 0, 0.06);
		border-radius: 16px;
		margin-top: 40px;
		padding: 20px 0;
		/* border: 1px solid #DADEE4; */
	}
</style>