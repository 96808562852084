<template>
	<div class="flexCol alit">
		<div class="yqlj">友情链接</div>
		<div class="flex alit" style="margin-bottom: 15px;">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%87%8D%E5%BA%86%E5%B8%82%E6%94%BF%E5%BA%9C%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" @click="openUrl('https://www.cq.gov.cn/')" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%87%8D%E5%BA%86%E5%B8%82%E5%95%86%E5%8A%A1%E5%A7%94%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" @click="openUrl('https://sww.cq.gov.cn/')" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%87%8D%E5%BA%86%E5%B8%82%E5%9B%BD%E8%B5%84%E5%A7%94%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" @click="openUrl('https://gzw.cq.gov.cn/')" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%87%8D%E5%BA%86%E5%B8%82%E5%86%9C%E4%B8%9A%E5%86%9C%E6%9D%91%E5%A7%94%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" @click="openUrl('https://nyncw.cq.gov.cn/')" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%87%8D%E5%BA%86%E5%B8%82%E8%B4%A8%E9%87%8F%E7%9B%91%E7%9D%A3%E7%AE%A1%E7%90%86%E5%B1%80%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" @click="openUrl('https://scjgj.cq.gov.cn/')" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%87%8D%E5%BA%86%E5%B8%82%E6%B8%9D%E4%B8%AD%E5%8C%BA%E6%94%BF%E5%BA%9C%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" @click="openUrl('https://www.cqyz.gov.cn/')" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E7%81%AB%E9%94%85%E5%8D%8F%E4%BC%9A%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;"  /> 
				<!-- @click="openUrl('https://sww.cq.gov.cn/')" -->
		</div>
		<div class="flex alit" style="margin-bottom: 15px;">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E6%AD%A6%E9%9A%86%E8%8B%95%E7%B2%89%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E5%87%AF%E9%B8%BF%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E6%96%87%E6%98%8C%E8%B4%A1%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E5%B0%8F%E6%9D%91%E7%89%A7%E5%93%A5%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%92%B0%E9%91%AB-%E6%B1%BD%E6%B0%B4.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E6%A2%93%E6%98%8C%E5%95%86%E8%B4%B8.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E7%A6%8F%E5%B7%9E%E7%99%BE%E6%B4%8B.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
		</div>
		<div class="flex alit" style="margin-bottom: 45px;">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E7%9B%8A%E5%AE%A2%20%E6%8B%B7%E8%B4%9D%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/friendshipLink/%E9%92%B0%E9%91%AB%E9%9B%86%E5%9B%A2%20%E6%8B%B7%E8%B4%9D%402x.png"
				alt="" style="width: 242px;height: 129px;cursor: pointer;" />
			<div class="" style="width: 242px;height: 129px;"></div>
			<div class="" style="width: 242px;height: 129px;"></div>
			<div class="" style="width: 242px;height: 129px;"></div>
			<div class="" style="width: 242px;height: 129px;"></div>
			<div class="" style="width: 242px;height: 129px;"></div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		mounted() {

		},
		methods: {
			openUrl(e) {
				window.open(e)
			}
		}
	}
</script>

<style>
	.yqlj {
		font-size: 30px;
		color: #000;
		font-weight: 900;
		margin: 30px 0;
	}
</style>