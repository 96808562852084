<template>
	<div class="flexCol alit">
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/oneItemOneCode/1%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/oneItemOneCode/2%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/oneItemOneCode/3%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/oneItemOneCode/4%402x.png" width="100%"
			alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/oneItemOneCode/5%402x.png" width="100%"
			alt="" />
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style>
</style>